import {FontAwesomeIcon} from "@fortawesome/vue-fontawesome";

import analysisCard from "../ToolsCard.vue";
import moment from "moment";

import Button from "@/components/button";
import Input from "@/components/input";
import Table from "@/components/table";
import Tab from "@/components/tab";
import NeoTabsSwitch from "@/components/tabs-switch";
import Loader from "@/components/loader";
import Dropdown from "@/components/dropdown";
import Pagination from "@/components/pagination";
import axios from "../../../../axios/osint.js";
import SaasAxios from "@/axios";
import VueMultiselect from "@/components/vue-multiselect";
// import "vue-multiselect/dist/vue-multiselect.min.css";
import "@/assets/css/multiselect.scss";
import {get} from "lodash";
import neoSwitch from "@/components/toggle-switch";
import {getMonitoring} from "@/utils/functions";
import {monitoringSearchData, getBackgroundStatus, getResultsID} from "@/utils/monitoringTools.js";
import toolsHelper from "@/mixins/toolsHelper";
import {setUsernamesDropdownData, convertToCSV, csvFileName} from "../../../../utils/functions";
import {mapActions, mapGetters, mapMutations} from "vuex";
import NeoAnalyse from "@/components/analyse";
import _ from "lodash";
import JsonCSV from "vue-json-csv";
import toolsDisclaimer from "@/components/tools-disclaimer";
import {DateTime} from "luxon";

export default {
    name: "neo-dark-web",
    mixins: [toolsHelper],
    components: {
        "neo-input": Input,
        "neo-button": Button,
        "neo-table": Table,
        "neo-tab": Tab,
        "neo-tabs-switch": NeoTabsSwitch,
        "neo-pagination": Pagination,
        "font-awesome-icon": FontAwesomeIcon,
        "neo-loader": Loader,
        "neo-dropdown": Dropdown,
        "analysis-card": analysisCard,
        VueMultiselect,
        NeoAnalyse,
        neoSwitch,
        "download-csv": JsonCSV,
        toolsDisclaimer,
    },
    props: {
        osintGraphDrawerOpen: {
            type: Boolean,
            default: false,
        },
        toolData: {
            type: Array,
            default: () => [],
        },
        tool: {
            type: String,
            default: "",
        },
        toolSearchQueries: Array,
    },
    data() {
        return {
            loaderController: {
                queries: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
                darkWeb: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
                breaches: {
                    loading: false,
                    success: false,
                    filterLoading: false,
                    filterSuccess: false,
                },
            },
            alertMessage: {
                success: null,
                error: null,
            },
            isPasswordRevealed: null,
            case_id: "",
            breachedData: [],
            breachedWebsites: [],
            allDarkWeb: [],
            allBreaches: [],
            allQueries: [],
            filters: {},
            headers: [
                {
                    text: "",
                    value: "details",
                },
            ],
            breachesHeaders: [
                {
                    text: "Details",
                    value: "details",
                },
                {
                    text: "Breach Date",
                    value: "breachDate",
                },
                {
                    text: "Dataclasses",
                    value: "dataClasses",
                },
            ],
            itemKey: "id",
            breachesItemKey: "Title",
            searchCaseFilter: "",
            selectable: false,
            hasAction: false,
            pagination: {
                queries: {
                    page: 1,
                    perPage: 15,
                    total_page: 1,
                },
                darkWeb: {
                    page: 0,
                    perPage: 15,
                    total_page: 1,
                },
                breaches: {
                    page: 0,
                    perPage: 15,
                    total_page: 1,
                },
            },
            selectedItem: {
                email: null,
                phone: null,
                username: null,
                domain: null,
                name: null,
            },
            address: null,
            usernameList: [],
            phoneList: [],
            emailList: [],
            domainList: [],
            nameList: [],
            addressList: [],
            searchKeyword: "",
            fieldType: {
                text: "All Fields (Default)",
                value: "all",
            },
            fieldTypeOptions: [
                {
                    group: "Field Type",
                    categories: [
                        {
                            text: "All Fields (Default)",
                            value: "all",
                        },
                        {
                            text: "Email",
                            value: "email",
                        },
                        {
                            text: "IP Address",
                            value: "ip_address",
                        },
                        {
                            text: "Username",
                            value: "username",
                        },
                        {
                            text: "Password",
                            value: "password",
                        },
                        {
                            text: "Hashed Password",
                            value: "hashed_password",
                        },
                        {
                            text: "Name",
                            value: "name",
                        },
                        {
                            text: "Phone",
                            value: "phone",
                        },
                        {
                            text: "Domain",
                            value: "domain",
                        },
                        {
                            text: "Address",
                            value: "address",
                        },
                    ],
                },
            ],
            query: "",
            email: "",
            address: "",
            ip_address: "",
            password: "",
            hashed_password: "",
            username: "",
            name: "",
            domain: "",
            showResult: false,
            input_phone: "",
            selectedTab: "breaches",
            outputFilterData: [],
            tabs: [
                {
                    name: "Breaches",
                    value: "breaches",
                },
                {
                    name: "Query",
                    value: "query",
                },
            ],
            ro_tabs: [
                {
                    name: "Breaches",
                    value: "breaches",
                },
            ],
            triggerRoute: true,
            selectVal: {},
            searchClicked: false,
            isLoading: false,
            tabName: "",
            request_id: "",
            outputFilters: [
                {
                    name: "name",
                    label: "Name",
                    options: [],
                    selected: [],
                },
                {
                    name: "username",
                    label: "User Name",
                    options: [],
                    selected: [],
                },
                {
                    name: "email",
                    label: "Email",
                    options: [],
                    selected: [],
                },
                {
                    name: "phone",
                    label: "Phone",
                    options: [],
                    selected: [],
                },
                {
                    name: "address",
                    label: "Address",
                    options: [],
                    selected: [],
                },
            ],
        };
    },
    computed: {
        ...mapGetters(["getProduct", "getSelectedToolQuery", "getMonitoringDateFilter", "getReadOnlyMode", "getResearchData", "getAllToolsData", "getAllMonitoringData", "getCaseUsernames", "getCaseEmails", "getCaseDomains", "getCasePhones"]),

        fetchAllData() {
            if (this.getResearchData.personalDetails?.length || this.getResearchData.socialProfiles?.length) {
                this.getToolData();
            }
        },

        csvFields() {
            if (this.selectedTab === "query") {
                return ["title", "description", "link"];
            } else {
                return [];
            }
        },

        searchQuery() {
            return this.query || this.selectedItem["name"]?.value || this.selectedItem["username"]?.value || this.address || this.selectedItem["email"]?.value || this.ip_address || this.password || this.hashed_password || this.selectedItem["phone"]?.value || this.selectedItem["domain"]?.value;
        },

        isMonitoringTab() {
            return this.$route.name === "monitoring";
        },

        // this.adverseResults
        checkMarkAllDone() {
            if (this.breachedData && this.breachedData.length) return this.breachedData.every((result) => result.changes_viewed);
            else return false;
        },
        getOutputOptions() {
            // console.log(this.allDarkWeb)
            return this.outputFilters.map((el) => ({
                ...el,
                options: [
                    {
                        name: el.label,
                        categories: this.getuniqueOptions(this.breachedData, el.name),
                    },
                ],
            }));
        },
        getDarkWebData: {
            get() {
                let results = this.breachedData;
                if (this.checkIfFilterApplied) {
                    this.outputFilters.forEach((outf) => {
                        if (outf.selected.length)
                            results = results.filter((el) => {
                                return outf.selected.some((sel) => sel?.[outf.name]?.toLowerCase() === el?.[outf.name]?.toLowerCase());
                            });
                    });
                }
                return results;
            },
        },
        checkIfFilterApplied() {
            return this.outputFilters?.filter((el) => el.selected.length > 0).length;
        },
    },
    async mounted() {
        this.case_id = this.$route.params.id;
        this.tabName = this.$route.name;
        this.outputFilterData = [];
        const dataRes = this.getToolsData(this.tabName, "dark-web-tool");
        if (dataRes) this.redirectToQuery(dataRes);
        this.getToolData();
        this.ClearFilters();
    },
    watch: {
        "$route.query.q"(to, from) {
            if (this.triggerRoute) {
                this.redirectToQuery();
            }
        },
        getDarkWebData() {
            this.allDarkWeb = [];
            (this.pagination.darkWeb = {
                page: 0,
                perPage: 15,
                total_page: 1,
            }),
                (this.pagination.darkWeb.total_page = parseInt(this.getDarkWebData.length / this.pagination.darkWeb.perPage, 10));
            if (this.getDarkWebData.length % this.pagination.darkWeb.perPage) {
                this.pagination.darkWeb.total_page += 1;
            }
            this.paginateDarkWeb();
        },
        // searchKeyword() {
        //     this.showResult = false;
        // },

        // query() {
        //     this.showResult = false;
        // },

        // password() {
        //     this.showResult = false;
        // },

        // hashed_password() {
        //     this.showResult = false;
        // },

        // ip_address() {
        //     this.showResult = false;
        // },

        // selectedItem: {
        //     handler(val) {
        //         this.showResult = false;
        //     },
        //     deep: true,
        // },
    },
    beforeDestroy() {
        this.setToolsData(this.tabName, "dark-web-tool");
    },
    methods: {
        ...mapMutations(["SET_ALL_TOOLS_DATA", "SET_ALL_MONITORING_DATA"]),
        ...mapActions(["getAnalysisDropdownData", "getAllCaseUsernames", "getAllCaseEmails", "getAllCasePhones", "getAllCaseDomains"]),

        async getToolData() {
            this.phoneList = await this.getDropdownData("phone");
            this.emailList = await this.getDropdownData("email");
            this.usernameList = await this.getDropdownData("username");
            this.domainList = await this.getDropdownData("domain");
            this.nameList = await this.getDropdownData("name");
            // this.addressList = await this.getDropdownData("address")
        },

        async getDropdownData(field) {
            let data = this.toolData.filter((user) => user.val_type === field);
            return data;
        },

        getOptionsList(list) {
            return [
                {
                    group: list.split("List")[0],
                    categories: this[list],
                },
            ];
        },

        handleChecked(data) {
            let allChecked = false;
            let allUsers = [...this.usernameList, ...this.phoneList, ...this.emailList, ...this.domainList, ...this.nameList];
            this.$emit("updateToolData", "dark-web", allUsers, data);
            allChecked = allUsers?.length ? (allUsers.findIndex((n) => !n.visited) > -1 ? false : true) : false;

            this.$emit("handleChecked", allChecked);
        },

        convertToCSV(arr) {
            return convertToCSV(arr);
        },

        csvFileName(query, section) {
            return csvFileName(this.tool, query, section);
        },

        updateFieldType() {
            this.handleClearAll();
        },

        setInput(input, field) {
            this[input] = this.selectedItem[field]?.value && this.selectedItem[field].value !== {} ? this.selectedItem[field].value : null;
        },

        setTheCustomEmail(value) {
            this.email = value;
            this.selectedItem.email = {
                value: value,
            };
        },
        // setTheCustomAddress(value) {
        //     this.address = value;
        //     this.selectedItem.address = {
        //         value: value,
        //     };
        // },
        setTheCustomPhone(value) {
            this.input_phone = value;
            this.selectedItem.phone = {
                value: value,
            };
        },
        setTheCustomUsername(value) {
            this.username = value;
            this.selectedItem.username = {
                value: value,
            };
        },
        setTheCustomName(value) {
            this.name = value;
            this.selectedItem.name = {
                value: value,
            };
        },
        setTheCustomDomain(value) {
            this.domain = value;
            this.selectedItem.domain = {
                value: value,
            };
        },

        async setSearchedUser(searchKeyword, type, val_type) {
            const data = {
                value: searchKeyword,
                platform: "dark-web",
                val_type: val_type || type,
                visited: true,
            };
            let url = `research-methodology/${this.case_id}/tools/profiles`;
            let isSuccess = await setUsernamesDropdownData(url, data);
            // if (isSuccess) {
            let dataItem = this[`${type}List`].find((dataItem) => {
                return dataItem.value === searchKeyword;
            });
            if (dataItem) dataItem["visited"] = true;
            else if (data?.value) {
                this[`${type}List`].push(data);
                this.$emit("addCustomData", data);
            }
            this.handleChecked(data);
            // }
        },

        startLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = true;
        },
        successLoader(section) {
            this.loaderController[section].success = true;
            this.loaderController[section].loading = false;
        },
        resetLoader(section) {
            this.loaderController[section].success = false;
            this.loaderController[section].loading = false;
        },

        getTime(item) {
            let date = new Date(item.BreachDate);
            return date ? moment(item).format("DD-MMMM-YYYY") : "";
        },

        paginateQueries(pageNo) {
            this.pagination.queries.page = pageNo;

            this.getQueries(true);
            document
                .getElementsByClassName("dark_web_queries")[0]
                .querySelector("tbody")
                .scrollTo(0, 0);
        },

        paginateDarkWeb() {
            setTimeout(() => {
                if (document.getElementById("breach_data")?.scrollTop > document.getElementById("breach_data")?.scrollHeight - document.getElementById("breach_data")?.offsetHeight - 700) {
                    this.pagination.darkWeb.page = this.pagination.darkWeb.page + 1;

                    this.startLoader("darkWeb");
                    let max_entries = this.pagination.darkWeb.perPage;
                    if (this.getDarkWebData.length - this.allDarkWeb.length < this.pagination.darkWeb.perPage) max_entries = this.getDarkWebData.length - this.allDarkWeb.length;
                    for (var i = 0, j = max_entries; i < j; i++) {
                        if (this.pagination.darkWeb.page <= this.pagination.darkWeb.total_page) {
                            this.allDarkWeb.push(this.getDarkWebData[(this.pagination.darkWeb.page - 1) * this.pagination.darkWeb.perPage + i]);
                        }
                    }
                    this.successLoader("darkWeb");
                }
            }, 500);
        },

        paginateBreaches() {
            setTimeout(() => {
                if (document.getElementById("breach_websites")?.scrollTop > document.getElementById("breach_websites")?.scrollHeight - document.getElementById("breach_websites")?.offsetHeight - 700) {
                    this.pagination.breaches.page = this.pagination.breaches.page + 1;

                    this.startLoader("breaches");
                    let max_entries = this.pagination.breaches.perPage;
                    if (this.breachedWebsites.length - this.allBreaches.length < this.pagination.breaches.perPage) max_entries = this.breachedWebsites.length - this.allBreaches.length;
                    for (var i = 0, j = max_entries; i < j; i++) {
                        if (this.pagination.breaches.page <= this.pagination.breaches.total_page) this.allBreaches.push(this.breachedWebsites[(this.pagination.breaches.page - 1) * this.pagination.breaches.perPage + i]);
                    }
                    this.successLoader("breaches");
                }
            }, 500);
        },

        manualSearch() {
            this.triggerRoute = false;
            this.search();
        },

        async search() {
            this.ClearFilters();
            this.allDarkWeb = [];
            this.allBreaches = [];
            this.breachedData = [];
            this.breachedWebsites = [];
            this.pagination = {
                queries: {
                    page: 1,
                    perPage: 15,
                    total_page: 1,
                },
                darkWeb: {
                    page: 0,
                    perPage: 15,
                    total_page: 1,
                },
                breaches: {
                    page: 0,
                    perPage: 15,
                    total_page: 1,
                },
            };
            this.searchClicked = true;
            if (this.searchKeyword && this.selectedTab === "query") {
                if (this.isMonitoringTab) {
                    // await this.monitoringSearch();
                    return;
                }
                this.getQueries();
                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "dark-web-tool"});
            } else if ((this.query || this.email || this.address || this.ip_address || this.username || this.password || this.address || this.hashed_password || this.name || this.input_phone || this.domain) && this.selectedTab === "breaches") {
                if (this.isMonitoringTab) {
                    await this.monitoringSearch();
                    return;
                }
                await this.getDarkWeb();
                this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "dark-web-tool"});
            } else {
                this.$toast.error("Please enter a valid input");
            }
        },

        async getQueries(isNext) {
            this.startLoader("queries");
            const url = "/dark-web/query";
            const data = {
                query: this.searchKeyword,
                page: this.pagination.queries.page,
            };

            const response = await axios.post(url, data);
            try {
                if (response && response.data && response.data.data && response.data.data.data && response.data.data.data.length) {
                    if (isNext) {
                        this.allQueries = response.data.data.data;
                    } else {
                        this.allQueries = [];
                        this.allQueries = response.data.data.data;
                    }
                    let count = 0;
                    this.allQueries.forEach((query) => (query.count = count++));
                    this.pagination.queries.total_page = response.data.data.last_page;
                    this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "dark-web-tool"});
                    this.triggerRoute = true;
                    this.showResult = true;
                } else if (response) {
                    this.$toast.error("No data found");
                }
                this.request_id = response?.data?.query_id;
                this.redirectTo();
                this.resetLoader("queries");
            } catch (error) {
                this.resetLoader("queries");
                let message = "Please enter a valid input";
                if (error.response && error.response.detail && error.response.detail.length) message = error.response.detail[0].msg;
                if (error.response && error.response.status !== 406) this.$toast.error(message);
            }
        },

        toggleReveal(event, item) {
            event.preventDefault();
            item.show = !item.show;
            this.$forceUpdate();
        },

        async getDarkWeb(isNext) {
            this.startLoader("darkWeb");
            const url = "/dark-web/search";
            const data = {};

            if (this.query) data["query"] = this.query;
            if (this.email) data["email"] = this.email.trim();
            if (this.ip_address) data["ip_address"] = this.ip_address;
            if (this.username) data["username"] = this.username;
            if (this.password) data["password"] = this.password;
            if (this.hashed_password) data["hashed_password"] = this.hashed_password;
            if (this.name) data["name"] = this.name;
            if (this.input_phone) data["phone"] = this.input_phone;
            if (this.domain) data["domain"] = this.domain;
            if (this.address) data["address"] = this.address;
            if (this.email && !this.email.includes("?") && !this.email.includes("&") && !this.email.includes("*")) this.setSearchedUser(this.email, "email", this.selectedItem?.email?.val_type);
            if (this.input_phone && !this.input_phone.includes("?") && !this.input_phone.includes("&") && !this.input_phone.includes("*")) this.setSearchedUser(this.input_phone, "phone", this.selectedItem?.phone?.val_type);
            if (this.username && !this.username.includes("?") && !this.username.includes("&") && !this.username.includes("*")) this.setSearchedUser(this.username, "username", this.selectedItem?.username?.val_type);
            if (this.name && !this.name.includes("?") && !this.name.includes("&") && !this.name.includes("*")) this.setSearchedUser(this.name, "name", this.selectedItem?.name?.val_type);
            if (this.domain && !this.domain.includes("?") && !this.domain.includes("&") && !this.domain.includes("*")) this.setSearchedUser(this.domain, "domain", this.selectedItem?.domain?.val_type);
            // if (this.address && !this.address.includes("?") && !this.address.includes("&") && !this.address.includes("*")) this.setSearchedUser(this.address, "address", this.selectedItem?.domain?.val_type);

            const response = await axios.post(url, data, {
                headers: {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                },
            });
            try {
                if (response && response.data && response.data.data) {
                    if (response.data.data.length) {
                        if (isNext) {
                            this.breachedData = this.breachedData.concat(response.data.data);
                        } else {
                            this.breachedData = [];
                            this.breachedData = response.data.data;
                        }

                        this.pagination.darkWeb.total_page = parseInt(this.getDarkWebData.length / this.pagination.darkWeb.perPage, 10);

                        if (this.getDarkWebData.length % this.pagination.darkWeb.perPage) {
                            this.pagination.darkWeb.total_page += 1;
                        }

                        this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "dark-web-tool"});
                        this.triggerRoute = true;
                        this.showResult = true;
                        this.paginateDarkWeb();
                    } else if (response) {
                        this.$toast.error("No data found");
                    }
                } else {
                    if (response) {
                        let message = "Please enter a valid input";
                        if (response && response.data && response.data.message) {
                            message = response.data.message;
                        } else if (response && response.data) {
                            message = "No data found";
                        }
                        this.$toast.error(message);
                    }
                    this.triggerRoute = true;
                }
                this.request_id = response?.data?.query_id;
                if ((this.email || this.input_phone) && this.request_id) await this.getBreaches();
                this.redirectTo();
                this.resetLoader("darkWeb");
            } catch (error) {
                this.resetLoader("darkWeb");
                let message = "Please enter a valid input";
                if (error.response && error.response.detail && error.response.detail.length) message = error.response.detail[0].msg;
                if (error.response && error.response.status !== 406) this.$toast.error(message);
            }
        },

        async getBreaches(isNext) {
            this.startLoader("breaches");
            const url = "/dark-web/breaches";
            let data = {};
            if (this.email) {
                data = {
                    email: this.email.trim(),
                };
            } else if (this.input_phone) {
                data = {
                    phone: this.input_phone,
                };
            }

            const response = await axios.post(url, data, {
                headers: {
                    "x-tool-name": this.$route.params.toolName,
                    "x-visited": true,
                    "x-query-id": this.request_id,
                },
            });
            try {
                if (response && response.data && response.data.data) {
                    if (response.data.data.length) {
                        if (isNext) {
                            this.breachedWebsites = this.breachedWebsites.concat(response.data.data);
                        } else {
                            this.breachedWebsites = [];
                            this.breachedWebsites = response.data.data;
                        }
                        this.pagination.breaches.total_page = parseInt(this.breachedWebsites.length / this.pagination.breaches.perPage, 10);

                        if (this.breachedWebsites.length % this.pagination.breaches.perPage) {
                            this.pagination.breaches.total_page += 1;
                        }
                        this.SET_ALL_TOOLS_DATA({...this._data, "tool-name": "dark-web-tool"});
                        this.triggerRoute = true;
                        this.showResult = true;
                        this.paginateBreaches();
                    } else if (response) {
                        this.$toast.error("No data found");
                    }
                } else {
                    if (response) {
                        let message = "Please enter a valid input";
                        if (response && response.data && response.data.message) {
                            message = response.data.message;
                        } else if (response && response.data) {
                            message = "No data found";
                        }
                        this.$toast.error(message);
                    }
                    this.triggerRoute = true;
                }
                this.resetLoader("breaches");
            } catch (error) {
                this.resetLoader("breaches");
                let message = "Please enter a valid input";
                if (error.response && error.response.detail && error.response.detail.length) message = error.response.detail[0].msg;
                if (error.response && error.response.status !== 406) this.$toast.error(message);
            }
        },
        handleClearAll() {
            this.query = "";
            this.email = "";
            this.ip_address = "";
            this.username = "";
            this.password = "";
            this.hashed_password = "";
            this.name = "";
            this.input_phone = "";
            this.domain = "";
            this.address = "";
            this.selectedItem = {
                email: null,
                phone: null,
                username: null,
                domain: null,
                name: null,
            };
            this.redirectTo();
        },

        redirectTo() {
            let query = {};
            if (this.request_id)
                query = {
                    query_id: this.request_id,
                };
            this.$router.push({path: this.$route.path, query}).catch((err) => {});
        },

        redirectToQuery(dataRes) {
            //redirect to same query
            if (this.$route?.query?.query_id) {
                let {query_id} = this.$route.query;
                let selected_query = this.toolSearchQueries?.find((el) => el._id === query_id);

                const type = Object.keys(selected_query?.query)[0];
                const value = Object.values(selected_query?.query)[0];

                if (value && type) {
                    this.fieldType = type != "query" ? this.fieldTypeOptions[0].categories.find((n) => n.value == type) : {text: "All Fields (Default)", value: "all"};
                    switch (type) {
                        case "username":
                            this.selectVal["username"] = value;
                            this.setTheCustomUsername(value);
                            break;
                        case "email":
                            this.selectVal["email"] = value;
                            this.setTheCustomEmail(value);
                            break;
                        case "phone":
                            this.selectVal["phone"] = value;
                            this.setTheCustomPhone(value);
                            break;
                        case "domain":
                            this.selectVal["domain"] = value;
                            this.setTheCustomDomain(value);
                            break;
                        case "name":
                            this.selectVal["name"] = value;
                            this.setTheCustomName(value);
                            break;
                        case "address":
                            this.address = value;
                            break;
                        default:
                            if (type === "all") this.query = value;
                            else this[type] = value;
                            break;
                    }
                    if (!dataRes) this.search();
                    else this.redirectTo();
                } else if (this.searchClicked) {
                    if (!dataRes) this.search();
                    else this.redirectTo();
                }
            } else {
                this.redirectTo();
            }
        },
        onSelectFieldType({value}) {
            this.selectVal = {};
            this.showResult = false;
            this.searchClicked = false;
            if (this.$refs["analysis-tool-input"]) {
                this.$refs["analysis-tool-input"].search = "";
            }
        },
        onSelect(event, type) {
            if (this.selectVal[type] == event.value) {
                this.showResult = false;
                this.searchClicked = false;
            }
            this.$set(this.selectVal, type, event.value);
            this.$refs["analysis-tool-input"].search = event.value;
        },

        close(type) {
            this.selectedItem[type] = {
                value: this.selectVal[type],
            };
            if (type == "email") {
                this.email = this.selectVal[type];
            } else if (type == "username") {
                this.username = this.selectVal[type];
            } else if (type == "name") {
                this.name = this.selectVal[type];
            } else if (type == "phone") {
                this.input_phone = this.selectVal[type];
            } else if (type == "domain") {
                this.domain = this.selectVal[type];
            }
            this.$refs["analysis-tool-input"].search = this.selectVal[type];
        },
        handleChange(event, type) {
            this.$set(this.selectVal, type, event);
        },
        onInput() {
            this.showResult = false;
            this.searchClicked = false;
        },
        getMonitoring,
        getBackgroundStatus,
        getResultsID,

        async monitoringSearch() {
            let sources = ["dark-web-tool"];
            this.isLoading = true;
            let values = await monitoringSearchData(this.case_id, sources, this.$route.params.toolName, this.getMonitoringDateFilter, this.getSelectedToolQuery);
            this.breachedData = values;
            this.pagination.darkWeb.total_page = parseInt(this.breachedData.length / this.pagination.darkWeb.perPage, 10);

            if (this.breachedData.length % this.pagination.darkWeb.perPage) {
                this.pagination.darkWeb.total_page += 1;
            }
            this.showResult = true;
            this.isLoading = false;
            this.paginateDarkWeb();
        },

        async handleMonitoring(index) {
            // event.stopPropagation();
            let data;
            if (index >= 0) data = this.breachedData[index];
            this.breachedData[index].monitoring_status = !this.breachedData[index].monitoring_status;
            this.$forceUpdate();
            await SaasAxios.post("/data-monitoring", {
                product_name: this.getProduct.name,
                product_id: this.getProduct.key,
                case_id: this.$route.params.id,
                query: this.getSelectedToolQuery,
                tool: this.$route.params.toolName,
                frequency: 7,
                monitoring_type: "card",
                inherit_product_settings: false,
                inherit_product_id: "",
                card_id: data.doc_id,
                card_source: this.$route.params.toolName,
                status: data.monitoring_status ? "enabled" : "disabled",
            });
        },

        async handleDone({target}, index) {
            let data;
            if (index >= 0) data = this.breachedData[index];
            this.breachedData[index].changes_viewed = !this.breachedData[index].changes_viewed;
            this.$forceUpdate();
            const sources = ["dark-web-tool"];
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited", {
                sources,
                doc_id: data.monitoring_id,
                visited: target.checked,
            });
        },

        async handleMarkAsAllDone(event) {
            this.breachedData.forEach((result, index) => {
                this.breachedData[index].changes_viewed = event.target.checked;
            });
            this.$forceUpdate();
            let sources = ["dark-web-tool"];
            await SaasAxios.post("/data-monitoring/mark-monitered-data-visited/query/all", {
                sources: sources,
                mark_all_as_visited: event.target.checked,
                query_id: this.getSelectedToolQuery.query_id,
            });
        },
        getFormattedTime(ts) {
            let date = DateTime.fromISO(ts);
            return date.toFormat("dd MMM yyyy");
        },
        getuniqueOptions(options, name) {
            let new_options = [];
            options.forEach((el) => {
                let element = new_options.find((option) => option[name].trim().toLowerCase() === el[name].trim().toLowerCase());
                !element && el[name].trim().length > 0 && new_options.push(el);
            });
            return new_options;
        },
        handleChangeSelected(event, name) {
            this.outputFilters = this.outputFilters.map((el) => {
                if (el.name === name) {
                    return {...el, selected: event};
                } else {
                    return el;
                }
            });
        },
        ClearFilters() {
            this.outputFilters = this.outputFilters.map((el) => ({...el, selected: []}));
        },
    },
};
